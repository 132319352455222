import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "p-10 space-y-8 bg-white border rounded-lg" }
const _hoisted_3 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_4 = { class: "grid lg:grid-cols-2 grid-cols-1 gap-6" }
const _hoisted_5 = { class: "flex flex-row items-center justify-between mb-3" }
const _hoisted_6 = { class: "inline-block text-base font-medium leading-tight" }
const _hoisted_7 = { class: "h-12 items-center justify-between bg-white border border-gray-300 rounded" }
const _hoisted_8 = { class: "space-x-4" }
const _hoisted_9 = { class: "p-10 bg-white border rounded-lg" }
const _hoisted_10 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_11 = { class: "mt-4 text-lg" }
const _hoisted_12 = { class: "p-10 bg-white border rounded-lg" }
const _hoisted_13 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_14 = { class: "mt-4 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnitsNumberInput = _resolveComponent("UnitsNumberInput")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('platform.settings.account-setting')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_UnitsNumberInput, {
          modelValue: _ctx.inactivityTimeoutString,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inactivityTimeoutString) = $event)),
          min: 5,
          max: 60,
          "auto-correct": "",
          units: _ctx.$t('platform.common.date-time.minutes.abbreviation'),
          label: _ctx.$t('platform.profile.automatic-logout'),
          error: _ctx.errors.inactivity_timeout_seconds ? _ctx.errors.inactivity_timeout_seconds[0] : null,
          onBlur: _ctx.updateInactivityTimeoutMinutes
        }, _createSlots({ _: 2 }, [
          (_ctx.inactivity_timeout_minutes !== 30)
            ? {
                name: "actions",
                fn: _withCtx(() => [
                  _createElementVNode("a", {
                    href: "#",
                    class: "text-primary-500 text-sm no-underline hover:underline",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.inactivity_timeout_minutes = 30), ["prevent"]))
                  }, _toDisplayString(_ctx.$t('platform.common.reset-default-label')), 1)
                ])
              }
            : undefined
        ]), 1032, ["modelValue", "units", "label", "error", "onBlur"]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.profile.mfa')), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BaseSwitch, {
              modelValue: _ctx.requires_mfa,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.requires_mfa) = $event)),
              "wrapper-class": "p-4",
              "label-position": "right",
              label: _ctx.requires_mfa ? _ctx.$t('platform.profile.enabled') : _ctx.$t('platform.profile.mfa_enable')
            }, null, 8, ["modelValue", "label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BaseButton, {
          size: "large",
          loading: _ctx.saving,
          onClick: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
          ]),
          _: 1
        }, 8, ["loading", "onClick"]),
        _createVNode(_component_BaseButton, {
          size: "large",
          color: "ghost",
          disabled: _ctx.saving,
          onClick: _ctx.cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("h1", _hoisted_10, _toDisplayString(_ctx.$t('platform.profile.reset-mfa')), 1),
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('platform.profile.reset-mfa-message')), 1),
      _createVNode(_component_BaseButton, {
        class: "mt-8",
        size: "large",
        color: "ghost",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showMFAModal = true))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.profile.reset-mfa-auth')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("h1", _hoisted_13, _toDisplayString(_ctx.$t('platform.profile.reset-password')), 1),
      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('platform.profile.reset-password-message')), 1),
      _createVNode(_component_BaseButton, {
        class: "mt-8",
        size: "large",
        color: "ghost",
        onClick: _ctx.requestPasswordReset
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.profile.reset-password-button')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (_ctx.showMFAModal)
      ? (_openBlock(), _createBlock(_component_ActionModal, {
          key: 0,
          title: _ctx.$t('platform.profile.reset-mfa'),
          body: _ctx.$t('platform.profile.reset-mfa-action'),
          actions: _ctx.mfaModalActions,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showMFAModal = false))
        }, null, 8, ["title", "body", "actions"]))
      : _createCommentVNode("", true)
  ]))
}